<template>
  <UtilitiesErrorBoundary tag="row">
    <LinksContentfulLink v-if="row.sys.id" :id="row.sys.id" label="row" />
    <template v-if="row?.fields?.rowStyle === 'Variation Container'">
      <ModularExperimentGroup :experimental-row="experimentalRow" />
    </template>
    <template v-else>
      <component
        :is="component"
        :id="row.fields.row_id"
        class="row"
        :class="allClasses"
        v-bind="currentProperties"
      >
        <template
          v-if="
            row.fields.content &&
            (row.fields.heroType === 'Full Responsive' ||
              row.fields.heroType === 'Full Responsive Short' ||
              row.fields.heroType === 'Full Responsive Split' ||
              row.fields.heroType === 'Full Responsive Split Pattern 1' ||
              row.fields.heroType === 'Full Responsive Split Pattern 2' ||
              row.fields.heroType === 'Full Responsive Split Oval' ||
              row.fields.heroType === 'Split' ||
              row.fields.heroType === 'Split Reverse' ||
              row.fields.heroType === 'Shape Overlay Hero')
          "
        >
          <ModularColumn
            v-for="(column, index) in row.fields.content"
            :id="column.sys.id"
            :key="index"
            :column="column"
          />
        </template>
      </component>
    </template>
  </UtilitiesErrorBoundary>
</template>

<script>
  import { defineAsyncComponent, provide } from 'vue';
  import {
    createRouter,
    createMemoryHistory,
    createWebHistory,
  } from 'vue-router';
  import { getNestedProperty } from '~/utils';
  import { AnalyticsEvents } from '~/types/enums/emitter-events';

  export default {
    props: {
      row: {
        type: Object,
        required: true,
      },
    },
    async setup(props) {
      const classes = useAttrs().class;

      const rowStyle = computed(() =>
        props.row.fields.rowStyle ? props.row.fields.rowStyle : undefined
      );

      const experiment = useState('experiment', () => '');

      if (process.server) {
        if (
          props.row.fields.experimentId &&
          rowStyle.value === 'Variation Container'
        ) {
          const ssrExp = await useLaunchDarklyServer(
            props.row.fields.experimentId
          );
          experiment.value = ssrExp;
        }
      }

      provide('ModularRow:rowStyle', rowStyle.value);

      return {
        classes,
        experiment,
      };
    },
    computed: {
      allClasses() {
        return `${this.classes ? this.classes : ''} ${this.bgColorClasses}`;
      },
      heroType() {
        return this.row.fields.heroType ? this.row.fields.heroType : undefined;
      },
      currentProperties() {
        if (
          this.row.fields.heroType === 'Full Responsive' ||
          this.row.fields.heroType === 'Full Responsive Short' ||
          this.row.fields.heroType === 'Full Responsive Split' ||
          this.row.fields.heroType === 'Full Responsive Split Pattern 1' ||
          this.row.fields.heroType === 'Full Responsive Split Pattern 2' ||
          this.row.fields.heroType === 'Full Responsive Split Oval' ||
          this.row.fields.heroType === 'Shape Overlay Hero'
        ) {
          return {
            hero: this.row.fields,
            id: this.row.sys.id,
          };
        } else if (
          this.row.fields.heroType === 'Split' ||
          this.row.fields.heroType === 'Split Reverse'
        ) {
          return {
            hero: this.row.fields,
            type: this.row.fields.heroType ? this.row.fields.heroType : 'Split',
          };
        } else if (this.row.fields.heroType === 'Cards') {
          return {
            content: this.row.fields,
          };
        } else {
          return {
            row: this.row,
            isVideo: this.isVideo,
            innerWrapperClasses: this.container,
            outerWrapperClasses: this.wrapClasses,
            rowEntryWrapperClasses: this.rowClasses,
          };
        }
      },
      heroClasses() {
        const classes = {
          'Full Responsive': 'z-10 lg:ml-1/12 ml-0',
          'Full Responsive Short': 'z-10 lg:ml-1/12 ml-0',
          'Full Responsive Split Pattern 1': 'z-10 lg:ml-1/12 ml-0',
          'Full Responsive Split Pattern 2': 'z-10 lg:ml-1/12 ml-0',
          'Full Responsive Split Oval': 'z-10 lg:ml-1/12 ml-0',
        };
        return classes[this.row.fields.heroType];
      },
      component() {
        const components = new Map([
          [
            'Full Responsive',
            defineAsyncComponent(
              () => import('../heroes/FullHeroResponsive.vue')
            ),
          ],
          [
            'Full Responsive Short',
            defineAsyncComponent(
              () => import('../heroes/FullHeroResponsiveShort.vue')
            ),
          ],
          [
            'Full Responsive Split',
            defineAsyncComponent(
              () => import('../heroes/FullHeroResponsiveSplit.vue')
            ),
          ],
          [
            'Full Responsive Split Pattern 1',
            defineAsyncComponent(
              () => import('../heroes/FullHeroResponsiveSplitPattern1.vue')
            ),
          ],
          [
            'Full Responsive Split Pattern 2',
            defineAsyncComponent(
              () => import('../heroes/FullHeroResponsiveSplitPattern2.vue')
            ),
          ],
          [
            'Full Responsive Split Oval',
            defineAsyncComponent(
              () => import('../heroes/FullHeroResponsiveSplitOval.vue')
            ),
          ],
          [
            'Shape Overlay Hero',
            defineAsyncComponent(
              () => import('../heroes/ShapeOverlayHero.vue')
            ),
          ],
          [
            'Split',
            defineAsyncComponent(() => import('../heroes/SplitHero.vue')),
          ],
          [
            'Split Reverse',
            defineAsyncComponent(() => import('../heroes/SplitHero.vue')),
          ],
          [
            'Row With Background Media Container',
            defineAsyncComponent(
              () => import('./RowWithBackgroundMediaContainer.vue')
            ),
          ],
          [
            'Row With Background Media',
            defineAsyncComponent(() => import('./RowWithBackgroundMedia.vue')),
          ],
          [
            'Hero Bottom',
            defineAsyncComponent(() => import('../heroes/HeroBottom.vue')),
          ],
          [
            'Cards',
            defineAsyncComponent(() => import('../heroes/HeroCardModal.vue')),
          ],
          ['Modal Popup', defineAsyncComponent(() => import('./RowModal.vue'))],
          [
            'Row Default',
            defineAsyncComponent(() => import('./RowDefault.vue')),
          ],
        ]);

        if (
          this.row.fields.heroType === 'Full Responsive' ||
          this.row.fields.heroType === 'Full Responsive Short' ||
          this.row.fields.heroType === 'Full Responsive Split' ||
          this.row.fields.heroType === 'Full Responsive Split Pattern 1' ||
          this.row.fields.heroType === 'Full Responsive Split Pattern 2' ||
          this.row.fields.heroType === 'Full Responsive Split Oval' ||
          this.row.fields.heroType === 'Split' ||
          this.row.fields.heroType === 'Split Reverse' ||
          this.row.fields.heroType === 'Cards' ||
          this.row.fields.heroType === 'Shape Overlay Hero'
        ) {
          return components.get(this.row.fields.heroType);
        } else if (this.row.fields.rowStyle === 'Modal Popup') {
          return components.get(this.row.fields.rowStyle);
        } else if (this.row.fields.backgroundMediaContainer) {
          return components.get('Row With Background Media Container');
        } else if (
          this.row.fields.backgroundImage &&
          this.row.fields.heroType !== 'Hero Top' &&
          this.row.fields.heroType !== 'Hero Bottom'
        ) {
          return components.get('Row With Background Media'); // Broken
        } else if (
          this.row.fields.backgroundImage &&
          this.row.fields.rowStyle === 'Hero Bottom'
        ) {
          return components.get(this.row.fields.rowStyle);
        } else {
          return components.get('Row Default');
        }
      },
      bgColorClasses() {
        const bgColors = {
          'Light Gray': 'bg-gray-lighter',
          'Light Blue': 'bg-blue-lighter',
          'Dark Blue': 'bg-blue-dark text--all-white',
          'Vertical 70% Dark Blue': 'bg--70-dark-blue text--all-white',
          'Vertical Half Light Blue': 'bg--half-light-blue',
          'Horizontal 80% Light Blue': 'bg--eighty-percent-light-blue',
          'Horizontal 80% Light Blue Reverse':
            'bg--eighty-percent-light-blue bg--eighty-percent-light-blue-reverse',
          'Dark Gray': 'bg-gray-darkest text--all-white',
          White: 'bg-white',
          Black: 'bg-[#111111] text--all-white',
          Green: 'bg-green',
          '75% Light Blue Pattern':
            'bg-light-blue bg--seventyfive-percent-pattern',
          '50% Light Blue Pattern': 'bg-light-blue bg--fifty-percent-pattern',
        };
        if (this.row.fields.rowStyle === 'Modal Popup') {
          return '';
        } else {
          return this.row.fields.backgroundColor
            ? ` ${bgColors[this.row.fields.backgroundColor]}`
            : '';
        }
      },
      wrapClasses() {
        const bgColors = {
          'Light Gray': 'bg-gray-lighter',
          'Light Blue': 'bg-blue-lighter',
          'Dark Blue': 'bg-blue-dark text--all-white',
          'Vertical 70% Dark Blue': 'bg--70-dark-blue text--all-white',
          'Vertical Half Light Blue': 'bg--half-light-blue',
          'Horizontal 80% Light Blue': 'bg--eighty-percent-light-blue',
          'Horizontal 80% Light Blue Reverse':
            'bg--eighty-percent-light-blue bg--eighty-percent-light-blue-reverse',
          'Dark Gray': 'bg-gray-darkest text--all-white',
          White: 'bg-white',
          Green: 'bg-green',
          '75% Light Blue Pattern':
            'bg-light-blue bg--seventyfive-percent-pattern',
          '50% Light Blue Pattern': 'bg-light-blue bg--fifty-percent-pattern',
        };
        const bgImageClasses =
          getNestedProperty(this.row, 'fields.backgroundImage', false) &&
          getNestedProperty(
            this.row,
            'fields.backgroundImage.sys.contentType.sys.id',
            false
          ) === 'image' &&
          getNestedProperty(
            this.row,
            'fields.backgroundImage.fields.image.fields.file.url',
            false
          )
            ? ' bg-center bg-cover bg-no-repeat'
            : '';
        const bgColor =
          this.row.fields.backgroundColor &&
          bgColors[this.row.fields.backgroundColor]
            ? ` ${bgColors[this.row.fields.backgroundColor]}`
            : '';
        const heroTypes = {
          Tall: 'hero--tall',
          'Shape Overlay Hero': 'hero--tall',
          'Tall Left': 'hero--tall hero--tall-left',
          Narrow: 'hero--narrow',
          Contact: 'hero--contact bg--two-thirds-light-blue',
          'Contact Reverse':
            'hero--contact-reverse bg--two-thirds-light-blue bg--two-thirds-light-blue-reverse',
          'Fade Out': `hero--fade-out ${
            this.isVideo && this.isVideo === true ? 'hero--fade-out-video' : ''
          } h-[460px] flex relative items-center`,
        };
        const heroType = this.row.fields.heroType
          ? ` ${heroTypes[this.row.fields.heroType]}`
          : '';
        const bannerClasses = ` h-[460px] items-center flex text--all-white dim dim--light ${bgImageClasses}`;
        if (
          this.row.fields.rowStyle === 'Body' ||
          this.row.fields.rowStyle === 'Modal Popup'
        ) {
          return `${bgColor}${bgImageClasses}`;
        } else if (this.row.fields.rowStyle === 'Hero Top') {
          if (
            this.row.fields.heroType === 'Contact' ||
            this.row.fields.heroType === 'Contact Reverse'
          )
            return heroType;
          else if (this.row.fields.heroType === 'Fade Out') {
            return `${bgImageClasses} ${heroType} hero--top`;
          }
          return `${bannerClasses}${heroType} hero--top`;
        } else if (this.row.fields.rowStyle === 'Hero Bottom') {
          return ` text-center ${bannerClasses}`;
        } else {
          return '';
        }
      },
      customInnerWrapperClasses() {
        return this.row.fields.classes ? this.row.fields.classes.join(' ') : '';
      },
      container() {
        if (
          (this.row.fields.rowStyle === 'Body' &&
            this.row.fields.fullWidth === true) ||
          (typeof this.row.fields.content !== 'undefined' &&
            this.row.fields.content[0].sys.contentType.sys.id !==
              'modularColumn')
        ) {
          return '';
        } else {
          return `row-container w-full px-gap2 lg:container z-10 ${
            ' ' + this.customInnerWrapperClasses
          }`;
        }
      },
      rowClasses() {
        // Return all template literals with 1 space in the beginning so class output stays clean if an empty string is returned
        const paddingClassDesktop = {
          Default: '4',
          '0px': '0',
          '20px': '1',
          '35px': '2',
          '50px': '3',
          '80px': '4',
          '120px': '5',
          '180px': '6',
        };
        const marginClassDesktop = {
          Default: '0',
          '0px': '0',
          '20px': '1',
          '35px': '2',
          '50px': '3',
          '80px': '4',
          '120px': '5',
          '180px': '6',
        };
        const marginClassMobile = {
          Default: '0',
          '0px': '0',
          '20px': '1',
          '35px': '2',
          '50px': '3',
          '80px': '4',
          '120px': '5',
          '180px': '6',
        };
        const paddingClassMobile = {
          Default: '3',
          '0px': '0',
          '20px': '1',
          '35px': '2',
          '50px': '3',
          '80px': '4',
          '120px': '5',
          '180px': '6',
        };
        const marginClasses = {
          marginDesktopTop: this.row.fields.desktopMarginTop
            ? ` md:mt-${marginClassDesktop[this.row.fields.desktopMarginTop]}`
            : ` ${marginClassDesktop.Default}`,
          marginDesktopBottom: this.row.fields.desktopMarginBottom
            ? ` md:mb-${
                marginClassDesktop[this.row.fields.desktopMarginBottom]
              }`
            : ` md:mb-${marginClassDesktop.Default}`,
          marginMobileTop: this.row.fields.mobileMarginTop
            ? ` mt-${marginClassMobile[this.row.fields.mobileMarginTop]}`
            : ` ${marginClassMobile.Default}`,
          marginMobileBottom: this.row.fields.mobileMarginBottom
            ? ` mb-${marginClassMobile[this.row.fields.mobileMarginBottom]}`
            : ` ${marginClassMobile.Default}`,
        };
        const paddingClasses = {
          paddingDesktopTop: this.row.fields.desktopPaddingTop
            ? ` md:pt-${paddingClassDesktop[this.row.fields.desktopPaddingTop]}`
            : ` md:pt-${paddingClassDesktop.Default}`,
          paddingDesktopBottom: this.row.fields.desktopPaddingBottom
            ? ` md:pb-${
                paddingClassDesktop[this.row.fields.desktopPaddingBottom]
              }`
            : ` md:pb-${paddingClassDesktop.Default}`,
          paddingMobileTop: this.row.fields.mobilePaddingTop
            ? ` pt-${paddingClassMobile[this.row.fields.mobilePaddingTop]}`
            : ` pt-${paddingClassMobile.Default}`,
          paddingMobileBottom: this.row.fields.mobilePaddingBottom
            ? ` pb-${paddingClassMobile[this.row.fields.mobilePaddingBottom]}`
            : ` pb-${paddingClassMobile.Default}`,
        };
        // purgecss: pt-0 pt-1 pt-2 pt-3 pt-4 pt-5 pt-6 pb-0 pb-1 pb-2 pb-3 pb-4 pb-5 pb-6 md:pt-0 md:pt-1 md:pt-2 md:pt-3 md:pt-4 md:pt-5 md:pt-6 md:pb-0 md:pb-1 md:pb-2 md:pb-3 md:pb-4 md:pb-5 md:pb-6
        const mobileSwap =
          this.row.fields.swapOrderMobile === true
            ? ' flex-col-reverse md:flex-row items-start'
            : '';
        const columnLayoutClasses =
          this.row.fields.content &&
          this.row.fields.content[0].sys.contentType.sys.id === 'modularColumn'
            ? 'columns-wrapper flex -mx-gap flex-wrap'
            : '';
        return `${columnLayoutClasses}${mobileSwap}${paddingClasses.paddingDesktopTop}${paddingClasses.paddingDesktopBottom}${marginClasses.marginDesktopTop}${marginClasses.marginDesktopBottom}${paddingClasses.paddingMobileBottom}${paddingClasses.paddingMobileTop}${marginClasses.marginMobileTop}${marginClasses.marginMobileBottom}`;
      },
      isImage() {
        return !!(
          this.row.fields.backgroundImage &&
          this.row.fields.backgroundImage.sys.contentType.sys.id === 'image'
        );
      },
      isVideo() {
        return !!(
          this.row.fields.backgroundImage &&
          this.row.fields.backgroundImage.sys.contentType.sys.id === 'video'
        );
      },
      id() {
        return this.row.sys.id;
      },
      experimentalRow() {
        if (this.row.fields.rowStyle === 'Variation Container') {
          return this.row.fields.content.find(
            (row) => row.fields.variationId === String(this.experiment)
          );
        }
        return null;
      },
    },
    mounted() {
      const isServer = typeof window === 'undefined';
      const history = isServer ? createMemoryHistory() : createWebHistory();
      const router = createRouter({ routes: [], history });
      const route = router.currentRoute;
      const hash = route && route.value && route.value.hash;

      if (hash && hash !== '') {
        const newHash = hash.replace('#', '');
        if (document.querySelector(`#${newHash}`)) {
          if (document.querySelectorAll(`.row #${newHash} video`).length > 1) {
            document
              .querySelectorAll(`.row #${newHash} video`)
              .forEach((video) => {
                video.classList.add('isLoaded');
              });
          }
          router.replace({
            path: route.path.split('#')[0],
            hash: '',
          });
          this.scrollTo(newHash);
        }
      }
      this.$emitter.on('scroll-to-id', (value) => {
        if (document.querySelector(`.row#${value.id}`)) {
          this.scrollTo(value.id);
        }
      });
      if (
        this.row.fields.rowStyle === 'Variation Container' &&
        this.row.fields.experimentName
      ) {
        this.$emitter.emit(AnalyticsEvents.TRACK, {
          event: `${this.row.fields.experimentName} Evaluated`,
          experiment_id: this.row.fields.experimentName,
          flagKey: this.row.fields.experimentName,
          variation_id: this.experiment,
        });
      }
    },
    methods: {
      scrollTo(id) {
        if (document.querySelector(`.row#${id}`)) {
          window.scrollTo({
            top:
              document.querySelector(`.row#${id}`).getBoundingClientRect().top +
              window.scrollY -
              100,
            behavior: 'instant',
          });
          setTimeout(() => {
            this.$emitter.emit('subnav-sticky-active');
          }, 200);
        }
      },
    },
  };
</script>

<style lang="scss">
  // Non scoped so changes filter down

  .bg--two-thirds-light-blue {
    background: linear-gradient(
      to right,
      theme('colors.blue.lighter') 66%,
      theme('colors.white') 33%
    );
  }
  .bg--two-thirds-light-blue-reverse {
    background: linear-gradient(
      to left,
      theme('colors.blue.lighter') 66%,
      theme('colors.white') 33%
    );
  }
  .bg--half-light-blue {
    background: linear-gradient(
      to bottom,
      theme('colors.blue.lighter') 60%,
      theme('colors.white') 30%
    );
  }
  .bg--70-dark-blue {
    background: linear-gradient(
      theme('colors.blue.dark') 70%,
      theme('colors.white') 30%
    );
    background-size: 100% 101% !important;
  }

  .flex-col-reverse .column:not(:first-of-type) {
    @media (max-width: theme('screens.md')) {
      @apply mb-1;
    }
  }
  .flex-col-reverse .column:not(:last-of-type) {
    @media (max-width: theme('screens.md')) {
      @apply mb-0;
    }
  }
  .tabs-col-reverse {
    @media (min-width: theme('screens.md')) {
      @apply flex-row-reverse;
    }
    @media (max-width: theme('screens.md')) {
      @apply flex-col-reverse;
    }
  }
</style>
